// @flow
import { useEffect, useState, useRef, useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import History from './History'
import { Button, Row, Card, Col, PageTitle, Gap, Container, Dialog, Text, PermissionCheck, PageNotReachable, SomethingWentWrong, LicenseMissing, IconButton, OutlineButton } from 'components/ReUsable'

import DetailState from './Overview/detailStateIndicator'
import FuelCellSection from './FuelCell/fuelCellSection'
import BatterySection from './Battery/batterySection'
import FuelCartridgesSection from './FuelCartridges/fuelCartridgesSection'
import FuelManagerSection from './FuelManager/fuelManagerSection'
import FuelCellSettingsEditContent from './Dialog/FuelCellSettingsEdit/fuelCellSettingsEditContent'
import BatterySettingsEditContent from './Dialog/BatterySettings/batterySettingsEditContent'
import DeviceExportDownload from './Dialog/deviceExportDownload'
import OwnerDetails from './Overview/ownerDetails'
import XlsxExportDownload from './Dialog/xlsxExportDownload'
import UpdateDeviceNameDialog from './Dialog/updateDeviceNameDialog'
import { getProperty } from 'utils/helpers'
import TerminalDialog from './Dialog/terminalDialog'
import { ManageLicenseDialog } from './Dialog/manageLicenseDialog'
import ActivateLicenseKeyDialog from './Dialog/ActivateLicenseKey/activateLicenseKeyDialog'
import { DeviceOptions } from './Overview/deviceOptions'
import { getGraphMultisense } from 'apis/devices'

import {
  getDevice,
  deleteClaim,
  clearDevice,
  togglePageDialog,
  resetChargingCycle,
  chargeFuelCell,
  updateOperatingMode,
  resetFuelCell,
  resetFuelCellStatus,
  clearBatteryAlertPageDialog,
  clearDevicesPageDialog,
  resetRequest,
  resetDeviceExport,
  updateDeviceServiceMode
} from 'actions'

import { POLLING_MS_INTERVAL, DEVICE_DETAILS_PERMISSIONS_LIST } from 'appConstants'
import DeactivateTransportDialog from './Dialog/deactivateTransportDialog'
import GeneralInfo from './Overview/generalInfo'
import { DevicePageMainAlerts, DevicePageServiceModeAlert } from 'components/ReUsable/Alert/devicePageMainAlerts'
import Cluster from './Cluster'
import { MultiSense } from './Multisense'
import { IndexProps } from './Types/IndexTypes'
import DeviceTags from '../Tags/deviceTags'
import DeviceTagsSelectorPopOver from '../Tags/deviceTags/deviceTagSelectorPopOver'
import usePageVisibility from './hooks/usePageVisibility'

const StyledCollapseButton = styled.button`
  border: none;
  margin-top: 0.8rem;
  background: transparent;
  display: flex;
  flex-direction: ${(props: { expanded: boolean }) => (props.expanded ? 'column-reverse' : 'column')};
  align-items: center;

  & > span {
    color: var(--color-primary);
    font-family: digital-serial;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    display: ${(props: { expanded: boolean }) => (props.expanded ? 'none' : 'block')};
  }

  & > img {
    position: relative;
    top: ${(props: { expanded: boolean }) => (props.expanded ? '-14px' : '-12px')};
    margin-bottom: ${(props: { expanded: boolean }) => (props.expanded ? '-26px' : '-30px')};
    width: 4rem;
    transform: ${(props: { expanded: boolean }) => (props.expanded ? 'rotate(-90deg)' : 'rotate(90deg)')};
  }
`

const StyledDeviceTagListContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 2px;
`

export const StyledButtonPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin: 0 0 10px 0;

  button {
    height: 45px;
  }
`

const selectDeviceState = (state) => ({
  device: state?.devices?.current,
  battery: state?.battery,
  alert: state?.alert,
  pageDialog: state.devices?.pageDialog,
  dialog: state?.dialog,
  MultiSenseType: state?.devices.current?.details?.content?.multiSense?.variant,
  serial: state?.devices?.current?.serialNumber
})

export const Device = (props: IndexProps) => {
  const isPageVisible = usePageVisibility()
  const [expanded, setExpanded] = useState(false)
  const detailContainerRef = useRef<HTMLDivElement | null>(null)

  const { device, battery, alert, pageDialog, dialog, MultiSenseType, serial } = useSelector(selectDeviceState)
  const tags = device?.tags || []
  const { serialNumber } = props.match.params

  const dispatch = useDispatch()
  const _resetRequest = () => dispatch(resetRequest())
  const _resetDeviceExport = () => dispatch(resetDeviceExport())
  const _clearDevicesPageDialog = () => dispatch(clearDevicesPageDialog())
  const _clearBatteryAlertPageDialog = () => dispatch(clearBatteryAlertPageDialog())
  const _clearDevice = () => dispatch(clearDevice())
  const _resetChargingCycle = (payload: any) => dispatch(resetChargingCycle(payload))
  const _togglePageDialog = (payload?: any) => dispatch(togglePageDialog(payload))
  const _updateOperatingMode = (payload: any) => dispatch(updateOperatingMode(payload))
  const _resetFuelCellStatus = (payload: any) => dispatch(resetFuelCellStatus(payload))
  const _resetFuelCell = (payload: any) => dispatch(resetFuelCell(payload))
  const _chargeFuelCell = (payload: any) => dispatch(chargeFuelCell(payload))
  const _deleteClaim = (payload: any) => dispatch(deleteClaim(payload)) // eslint-disable-line @typescript-eslint/no-unused-vars
  const _getDevice = (payload: any) => dispatch(getDevice(payload))
  const _updateDeviceServiceMode = (payload: { serialNumber: string; serviceMode: boolean }) => dispatch(updateDeviceServiceMode(payload))

  // Polling depending on device detail page visibility + tab changes.
  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null
    if (isPageVisible) {
      // Fetch product details immediately on mount or when tab becomes active
      callGetDeviceRequest()

      // Start polling every 60 seconds when the page is visible
      intervalId = setInterval(callGetDeviceRequest, POLLING_MS_INTERVAL)
    }

    return () => {
      // Clear the interval when the component is unmounted or the tab is inactive
      if (intervalId) {
        clearInterval(intervalId)
        _clearDevice()
      }
    }
  }, [isPageVisible])

  const callGetDeviceRequest = useCallback(() => {
    if (serialNumber !== serial && serial !== undefined) {
      _clearDevice()
    }
    _getDevice({ serialNumber })
  }, [serial])

  const unclaimDevice = () => {
    _deleteClaim({ serialNumber: device.serialNumber })
  }

  const toggleServiceModeDevice = () => {
    _updateDeviceServiceMode({ serialNumber, serviceMode: !device.inServiceMode })
  }

  const handleToggleContent = () => {
    if (!expanded && props.isMobile) {
      detailContainerRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    setExpanded((prevExpanded) => !prevExpanded)
  }

  const { isDialogOpen, isPageDialogOpen, dialogId } = dialog
  const { requestErrorMessage, chargeFuelCellSuccess, resetStatus } = pageDialog

  if (!device) return <Spinner animation="border" variant="secondary" />
  if (device.error && device.error?.status === 403) return <PageNotReachable errorMessage={`You have no permission to access device ${serialNumber}`} />

  if (device.error && device.error?.status === 404) return <PageNotReachable />

  if (device.error && device.error?.status === 500) return <SomethingWentWrong />

  const deviceDetails = getProperty(device.details) || {}
  const deviceState = getProperty(device.state) || {}
  const permissions = device.permissions || {}
  const deviceOwner = getProperty(device.owner) || {}

  return (
    <main role="main" className={`${isDialogOpen ? 'blur-element' : ''}`}>
      <Container data-cy="page-device-details">
        <DevicePageMainAlerts isConnected={deviceState.isConnected} deviceLastUpdated={deviceState.lastUpdated} pageDialog={alert.pageDialog} isPageDialogOpen={isPageDialogOpen.isPageDialogOpen} />
        <DevicePageServiceModeAlert inServiceMode={device.inServiceMode} />
        <Row margin="1rem 0">
          <Col base="1px">
            <NavLink className={'device-backlink'} to={'/'}>
              <IconButton src="arrow-right" onClick={() => null} width="48px" />
            </NavLink>
          </Col>
          <Gap></Gap>
          <Col>
            <div className={'device-image'}>
              <img src={device.img_url} alt="Product" />
            </div>
          </Col>
          <Gap></Gap>
          <Col>
            <Row noWrap={true}>
              <PageTitle>{device.name}</PageTitle>
              <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.RENAME]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.RENAME}>
                <IconButton
                  style={{ position: 'relative', bottom: '10px', left: '-4px' }}
                  width="64px"
                  onClick={() => {
                    _togglePageDialog({ dialogId: 'update-device-name-dialog' })
                  }}
                  src="pen"
                />
              </PermissionCheck>
            </Row>
            <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.GENERAL_INFO_READ]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.GENERAL_INFO_READ}>
              <GeneralInfo />
            </PermissionCheck>
            <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.STATE_READ]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.STATE_READ}>
              <DetailState values={deviceState} inServiceMode={device.inServiceMode} />
            </PermissionCheck>
            <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.OWNER_READ]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.OWNER_READ}>
              <OwnerDetails owner={deviceOwner} />
            </PermissionCheck>
            <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.RENAME]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.RENAME}>
              <StyledDeviceTagListContainer data-cy="device-tags-container">
                {tags.length > 0 ? (
                  <span>Tags:</span>
                ) : (
                  <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TAGS_WRITE]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.TAGS_WRITE}>
                    <span>Add tags:&nbsp;</span>
                  </PermissionCheck>
                )}
                <DeviceTags tags={tags} compact={true} />
                <DeviceTagsSelectorPopOver serialNumber={serialNumber} id="device-tag-selector" onSave={callGetDeviceRequest} permissions={permissions} />
              </StyledDeviceTagListContainer>
            </PermissionCheck>
          </Col>
          <Gap></Gap>
          <Gap></Gap>
          <DeviceOptions />
        </Row>
        <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.DETAIL_READ]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.DETAIL_READ}>
          <>
            <Row ref={detailContainerRef}>
              <Col alignItems="stretch">
                <Card>
                  <Row>
                    <FuelCellSection
                      togglePageDialog={() => _togglePageDialog({ dialogId: deviceState.state === 'transport_lock' ? 'deactivate-transport-protection-dialog' : 'edit-fuel-cell-dialog' })}
                      toggleTerminalPageDialog={() => _togglePageDialog({ dialogId: 'terminal-dialog' })}
                      isExpanded={expanded}
                    />
                    <Gap></Gap>
                    <BatterySection
                      batteryData={deviceDetails.battery}
                      confirmResetChargingCycle={() => _togglePageDialog({ dialogId: 'reset-cycle-dialog' })}
                      isConnected={deviceState.isConnected}
                      isExpanded={expanded}
                    />
                    <Gap></Gap>
                    <Col alignItems="stretch">
                      <FuelCartridgesSection fuelCellCartridges={deviceDetails.fuelCartridges} isConnected={deviceState.isConnected} permissions={permissions} isExpanded={expanded} />
                      {deviceDetails.fuelCartridges && deviceDetails.fuelCartridges.maxNumberOfCartridges >= 2 && expanded && (
                        <FuelManagerSection device={device} fuelManagerInfo={deviceDetails.fuelManager} />
                      )}
                    </Col>
                  </Row>
                  <Row style={{ justifyContent: 'center' }}>
                    <StyledCollapseButton data-cy="expand-device-info" expanded={expanded} onClick={() => handleToggleContent()}>
                      <span>{expanded ? 'Click to collapse' : 'Click to expand'}</span>
                      <img alt={expanded ? 'Collapse' : 'Click to expand'} src="/imgs/arrow-right.svg" />
                    </StyledCollapseButton>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Cluster serialNumber={serialNumber} role={device?.details?.content?.cluster?.role} />
            <MultiSense serialNumber={serialNumber} multiSenseType={MultiSenseType} isConnected={deviceState.isConnected} deviceType={device?.deviceType || ''}/>
            <Row margin="1rem 0">
              <Col alignItems="stretch">
                <Card>
                  <Row>
                    <Col>
                      <History
                        serialNumber={serialNumber}
                        togglePageDialog={() => _togglePageDialog({ dialogId: 'xlsx-export-dialog' })}
                        permissions={permissions}
                        deviceType={device.deviceType}
                        getMultisenseData={getGraphMultisense}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </>
        </PermissionCheck>
      </Container>

      <Dialog
        id="device-export-dialog"
        className="modal-page"
        title={'Download Report'}
        show={isPageDialogOpen && dialogId === 'device-export-dialog'}
        onClose={() => {
          _togglePageDialog()
          _resetDeviceExport()
        }}
      >
        <DeviceExportDownload pageDialog={pageDialog} />
      </Dialog>

      <Dialog id="xlsx-export-dialog" className="modal-page" title={'Download XLSX Report'} show={isPageDialogOpen && dialogId === 'xlsx-export-dialog'} onClose={() => _togglePageDialog()}>
        <XlsxExportDownload pageDialog={pageDialog} />
      </Dialog>

      <Dialog
        id="edit-fuel-cell-dialog"
        className="modal-page"
        title={'Fuel Cell'}
        show={isPageDialogOpen && dialogId === 'edit-fuel-cell-dialog'}
        onClose={() => {
          _togglePageDialog()
          _clearDevicesPageDialog()
          _resetRequest()
        }}
      >
        <FuelCellSettingsEditContent
          serialNumber={device?.serialNumber}
          operatingMode={device?.details?.content?.fuelCell?.operatingMode || 'automatic'}
          chargeFuelCell={(payload) => _chargeFuelCell(payload)}
          updateOperatingMode={(payload) => _updateOperatingMode(payload)}
          resetFuelCell={(payload) => _resetFuelCell(payload)}
          resetFuelCellStatus={(payload) => _resetFuelCellStatus(payload)}
          resetStatus={resetStatus}
          requestErrorMessage={requestErrorMessage}
          chargeFuelCellSuccess={chargeFuelCellSuccess}
          togglePageDialog={() => _togglePageDialog()}
          permissions={permissions}
        />
      </Dialog>

      <Dialog
        id="deactivate-transport-protection-dialog"
        className="modal-page"
        title={'Deactivate transport protection'}
        show={isPageDialogOpen && deviceState.state === 'transport_lock' && dialogId === 'deactivate-transport-protection-dialog'}
        onClose={() => {
          _togglePageDialog()
          _clearDevicesPageDialog()
          _resetRequest() //  Refetch device data on closing the modal
          _getDevice({ serialNumber: device?.serialNumber })
        }}
      >
        <DeactivateTransportDialog serialNumber={device.serialNumber} />
      </Dialog>

      <Dialog
        id="edit-battery-dialog"
        className="modal-page"
        size="xl"
        title={'Set charge thresholds'}
        show={isPageDialogOpen && dialogId === 'edit-battery-dialog'}
        onClose={() => {
          _togglePageDialog()
          callGetDeviceRequest()
          _clearBatteryAlertPageDialog()
        }}
      >
        <BatterySettingsEditContent serialNumber={device && device.serialNumber} battery={battery} pageDialog={battery.pageDialog} deviceState={deviceState} />
      </Dialog>

      <Dialog id="remove-device-dialog" className="modal-page" title={'Remove EFOY device'} show={isPageDialogOpen && dialogId === 'remove-device-dialog'} onClose={() => _togglePageDialog()}>
        <Col alignItems="center">
          <Text textAlign="center">Are you sure you want to remove your EFOY „{device && device.name}“ from your account? The saved data will be deleted.</Text>
          <StyledButtonPanel>
            <OutlineButton type="button" id="cancel" color="primary" onClick={() => _togglePageDialog()}>
              CANCEL
            </OutlineButton>
            <Button
              onClick={() => {
                unclaimDevice()
              }}
              data-cy="confirm-botton"
            >
              Confirm
            </Button>
          </StyledButtonPanel>
        </Col>
      </Dialog>

      <Dialog id="set-service-mode-dialog" className="modal-page" title={'Set in service'} show={isPageDialogOpen && dialogId === 'set-service-mode-dialog'} onClose={() => _togglePageDialog()}>
        <Col alignItems="center">
          <Text textAlign="center">
            Are you sure you want to set the EFOY „{device && device.name}“ into the service mode? All alerts will be paused and the access of the customer will be reduced.
          </Text>
          <StyledButtonPanel>
            <OutlineButton type="button" id="cancel" color="primary" onClick={() => _togglePageDialog()}>
              CANCEL
            </OutlineButton>
            <Button
              onClick={() => {
                toggleServiceModeDevice()
              }}
              data-cy="confirm-botton"
            >
              {device.inServiceMode ? 'Disable' : 'Enable'}
            </Button>
          </StyledButtonPanel>
        </Col>
      </Dialog>

      <Dialog id="reset-cycle-dialog" className="modal-page" title={'Reset battery cycle'} show={isPageDialogOpen && dialogId === 'reset-cycle-dialog'} onClose={() => _togglePageDialog()}>
        <Col alignItems="center">
          <Text textAlign="center">Are you sure you want to reset your charging cycles of your EFOY Fuel Cell?</Text>
          <StyledButtonPanel>
            <OutlineButton type="button" id="cancel" color="primary" onClick={() => _togglePageDialog()}>
              CANCEL
            </OutlineButton>
            <Button
              onClick={() => {
                _resetChargingCycle({ serialNumber: device.serialNumber })
                _togglePageDialog()
              }}
            >
              Confirm
            </Button>
          </StyledButtonPanel>
        </Col>
      </Dialog>

      <Dialog
        id="update-device-name-dialog"
        className="modal-page"
        title={'Update EFOY device name'}
        show={isPageDialogOpen && dialogId === 'update-device-name-dialog'}
        onClose={() => _togglePageDialog()}
      >
        <UpdateDeviceNameDialog device={device} togglePageDialog={_togglePageDialog} />
      </Dialog>

      <Dialog id="terminal-dialog" className="modal-page" title={'Terminal'} size="lg" show={isPageDialogOpen && dialogId === 'terminal-dialog'} onClose={() => _togglePageDialog()}>
        <TerminalDialog serialNumber={device && device.serialNumber} />
      </Dialog>

      <Dialog
        id="manage-license-dialog"
        className="modal-page"
        title={'Licenses'}
        size="xl"
        show={isPageDialogOpen && dialogId === 'manage-license-dialog'}
        onClose={() => {
          _togglePageDialog()
          callGetDeviceRequest()
        }}
      >
        <ManageLicenseDialog />
      </Dialog>

      <Dialog
        id="add-license-dialog"
        className="modal-page"
        title={'Apply License'}
        show={isPageDialogOpen && dialogId === 'add-license-dialog'}
        onClose={() => {
          _togglePageDialog()
          callGetDeviceRequest()
        }}
      >
        <ActivateLicenseKeyDialog serialNumber={device && device.serialNumber} />
      </Dialog>

      <Dialog
        id="license-missing-dialog"
        className="modal-page"
        title={'License Missing'}
        size="lg"
        show={isPageDialogOpen && dialogId === 'license-missing-dialog'}
        onClose={() => _togglePageDialog()}
      >
        <LicenseMissing />
      </Dialog>
    </main>
  )
}

export default Device
