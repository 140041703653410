import styled from 'styled-components'
import {
  Col,
  CardSectionTitle,
  CardSection,
  DataRow,
  DataRowTitle,
  DataRowValue,
  DataRowAdditionalValue,
  ShowDataValue,
  ShowDataValueRounded,
  ShowErrorLink,
  ShowTimeStamp,
  PermissionCheck,
  UpgradeLicenseButton,
  Row,
  Button,
  IconButton
} from 'components/ReUsable'
import { DEVICE_DETAILS_PERMISSIONS_LIST } from 'appConstants'
import { getProperty } from 'utils/helpers'
import { secondsToHours } from 'date-fns'
import { AnimatedContainer } from 'components/ReUsable/Card'
import { useSelector } from 'react-redux'

type FuelCellData = {
  activeError: string
  powerOutput: string
  voltageBattery: string
  voltageEfoy: string
  chargingCurrent: string
  efoyTemperature: string
  error: string
  lastError: string
  activeWarning: string
  lastWarning: string
  stackOperationTime: number
  operatingMode: string
}

type BatteryData = {
  batteryType: string
  averageChargingTimeManual: string
  averageChargingTimeAutomatic: string
  switchOnVoltage: string
  cutOffCurrent: string
  batteryCapacity: string
  switchOnDelay: string
  deepDischargeProtection: string
  cutOffTime: string
  minChargeTime: string
  maxStateOfCharge: string
  maxDepthOfDischarge: string
  switchOffVoltage: string
  voltageBattery: string
  chargingCurrent: string
}

type FirmwareVersion = {
  major: string
  minor: string
  patch: string
}

type DeviceState = {
  state: string
  isConnected: boolean
  lastUpdated: string
}

type Props = {
  isExpanded: boolean
  toggleTerminalPageDialog: () => void
  togglePageDialog: () => void
}

const StyledFuelCellTitle = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledTransportLockImg = styled.img`
  width: 90%;
  height: auto;
  margin-top: 1rem;
`

const efoyVoltageVisibleFor = ['in_operation', 'shut_down', 'frost_protection', 'deep_discharge_protection', 'transport_lock_procedure', 'emergency_frost_protection']

const getOperatingMode = (mode: string) => (mode === 'off' ? 'SLEEP' : 'AUTO')

/**
 * Formats an error code string into a human-readable format.
 * @param {string} error the error code string
 * @returns {string} the formatted error code string
 * @example '123' -> 'E123'
 */
const getErrorCode = (error: string) =>
  ShowDataValue({
    data: error,
    properties: ['major', 'minor'],
    padStart: 3
  })

/**
 * Renders a DataRow component with a title and value.
 * @param {string} title the title of the DataRow
 * @param {React.ReactNode} value the value of the DataRow
 * @returns {React.ReactNode} a DataRow component
 * @example <DataRow title="Serial number" value="123456" />
 */
const renderDataRow = (title: string, value: React.ReactNode) => (
  <DataRow>
    <DataRowTitle>{title}</DataRowTitle>
    <DataRowValue>{value}</DataRowValue>
  </DataRow>
)

const DeviceState = (state) => ({
  device: state?.devices?.current
})

export default function FuelCellSection(props: Props) {
  const { device } = useSelector(DeviceState)
  const { toggleTerminalPageDialog, togglePageDialog, isExpanded } = props

  const deviceState: DeviceState = getProperty(device?.state) || {}

  const deviceDetails: any = getProperty(device?.details)
  const firmwareVersion: FirmwareVersion = deviceDetails.fuelCell && deviceDetails.fuelCell.firmwareVersion
  const fuelCellData: FuelCellData = deviceDetails.fuelCell || {}
  const permissions: any = device.permissions || {}
  const batteryData: BatteryData = deviceDetails.battery

  const isTransportMode = Object.keys(deviceState).length > 0 && deviceState.state === 'transport_lock'
  const isDeviceVoltageVisible = Object.keys(deviceState).length > 0 && efoyVoltageVisibleFor.indexOf(deviceState.state) > -1
  const { activeError, activeWarning, lastError, lastWarning } = fuelCellData || {}

  return (
    <Col>
      <CardSectionTitle>
        <StyledFuelCellTitle>
          Fuel Cell
          <span>
            <PermissionCheck permission={DEVICE_DETAILS_PERMISSIONS_LIST.DIRECT_COMMAND} value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.DIRECT_COMMAND]}>
              <IconButton
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => (deviceState.isConnected ? toggleTerminalPageDialog() : null)}
                src="monitor"
                disabled={!deviceState.isConnected}
                alt="Monitor"
                dataCy="fuel-cell-monitor"
              />
            </PermissionCheck>
            <UpgradeLicenseButton
              dataCy="edit-fuel-cell-section"
              isConnected={deviceState.isConnected}
              handleClick={togglePageDialog}
              permissionNames={isTransportMode ? [DEVICE_DETAILS_PERMISSIONS_LIST.TLP_UNLOCK] : [DEVICE_DETAILS_PERMISSIONS_LIST.OPERATION_MODE_WRITE, DEVICE_DETAILS_PERMISSIONS_LIST.RESET]}
            />
          </span>
        </StyledFuelCellTitle>
      </CardSectionTitle>
      <CardSection>
        {isTransportMode && (
          <DataRow>
            <DataRowTitle data-cy="transport-lock-row">Transport protection</DataRowTitle>
            <DataRowValue>
              <Row alignItems="center" data-cy="transport-lock-value">
                <Col alignItems="center">
                  <StyledTransportLockImg src={'/imgs/transport-lock.png'} alt="Transport-lock" />
                </Col>
                <Col>
                  <Button onClick={togglePageDialog}>Deactivate</Button>
                </Col>
              </Row>
            </DataRowValue>
          </DataRow>
        )}
        {renderDataRow('Operating mode', <ShowDataValue data={{ ...fuelCellData, operatingMode: getOperatingMode(fuelCellData.operatingMode) }} property="operatingMode" />)}
        {renderDataRow('Power output', <ShowDataValue showZero data={fuelCellData} property="powerOutput" suffix="W" decimals={2} />)}
        <AnimatedContainer {...{ expanded: isExpanded }} data-cy="fuel-cell-animated-container">
          {renderDataRow('Voltage at the battery', <ShowDataValueRounded data={batteryData} property="voltageBattery" places={1} suffix="V" decimals={1} />)}
          {isDeviceVoltageVisible && renderDataRow('Voltage at the EFOY', <ShowDataValueRounded data={fuelCellData} property="voltageEfoy" places={1} suffix="V" decimals={1} />)}
          {renderDataRow('Charging current', <ShowDataValueRounded data={batteryData} property="chargingCurrent" places={1} suffix="A" showZero />)}
          {renderDataRow('EFOY temperature', <ShowDataValueRounded data={fuelCellData} property="efoyTemperature" places={1} suffix="°C" />)}
          {renderDataRow(
            'Operating hours',
            <ShowDataValueRounded data={{ ...fuelCellData, stackOperationTime: secondsToHours(fuelCellData.stackOperationTime) }} property="stackOperationTime" suffix="h" />
          )}
          {renderDataRow('Firmware version', firmwareVersion ? `${firmwareVersion.major}.${firmwareVersion.minor}.${firmwareVersion.patch}` : <ShowDataValue data={undefined} />)}
        </AnimatedContainer>
        {renderDataRow('Active error', <ShowErrorLink data={device} errorCode={getErrorCode(activeError)} />)}
        <DataRowAdditionalValue>
          <ShowTimeStamp data={activeError} property="time" />
        </DataRowAdditionalValue>
        {renderDataRow(
          'Last error',
          <>
            <ShowErrorLink data={device} errorCode={getErrorCode(lastError)} />
            <DataRowAdditionalValue>
              <ShowTimeStamp data={lastError} property="time" />
            </DataRowAdditionalValue>
          </>
        )}

        {renderDataRow('Active warning', <ShowErrorLink data={device} errorCode={getErrorCode(activeWarning)} />)}
        <DataRowAdditionalValue>
          <ShowTimeStamp data={activeWarning} property="time" />
        </DataRowAdditionalValue>
        {renderDataRow(
          'Last warning',
          <>
            <ShowErrorLink data={device} errorCode={getErrorCode(lastWarning)} />
            <DataRowAdditionalValue>
              <ShowTimeStamp data={lastWarning} property="time" />
            </DataRowAdditionalValue>
          </>
        )}
      </CardSection>
    </Col>
  )
}
